.md-alert {
    display: none !important;
    text-align:center !important;
  }
  
  .md-alert-visible {
    display: block !important;
    text-align:center !important;
  }
  
  .md-alert-hidden {
    display: none !important;
  }
  